import React, { useState } from "react";
import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import { WcCheckBox, WcStack, WcTypography } from "@bpipartners/webui";
import { useTheme } from "@guberno/utils/theme";
import { TypeIcon } from "@guberno/ui";
import { MdArrowRight } from "react-icons/md";

interface CustomNodeProps {
  node: NodeModel;
  depth: number;
  hasChild: boolean;
  onToggle: (id: string | number) => void;
  isOpen: boolean;
  onSelect: (node: NodeModel) => void;
  isSelected: boolean;
}

export const CustomNodeFile = ({
  onToggle,
  depth,
  isOpen,
  node,
  onSelect,
  isSelected,
  hasChild,
}: CustomNodeProps) => {
  const { id, droppable, data } = node;
  const indent = depth * 24;
  const theme = useTheme();
  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => onSelect(node);

  const dragOverProps = useDragOver(id, isOpen, onToggle);

  return (
    <WcStack
      direction={"row"}
      sx={{
        width: "100%",

        paddingBottom: "6px",
        color: isSelected ? theme.appColors.primary : "inherit",
      }}
      id="CustomNodeFile"
      {...dragOverProps}
    >
      <WcStack
        sx={{
          fontSize: 0,
          cursor: "pointer",
          display: "flex",
          height: "24px",
          width: "24px",
          transition: "transform linear 0.1s",
          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
        }}
      >
        {hasChild && (
          <WcStack onClick={handleToggle}>
            <MdArrowRight fontSize="large" color={theme.appColors.primary} />
          </WcStack>
        )}
      </WcStack>

      <WcStack
        spacing={2}
        direction={"row"}
        width={"100%"}
        alignItems={"center"}
      >
        <WcStack>
          <TypeIcon
            //@ts-ignore
            itemType={data?.itemType}
          />
        </WcStack>

        <WcStack
          width={"100%"}
          paddingX={1}
          onClick={handleSelect}
          sx={{
            cursor: "pointer",
            "&:hover": {
              background: theme.appColors.secondary,
              color: theme.appColors.onSecondary,
            },
          }}
        >
          <WcTypography
            // @ts-ignore
            variant={node.data.itemType === "SUBJECT" ? "body1" : "body2"}
            // @ts-ignore
            fontWeight={node.data.itemType === "SUBJECT" ? "500" : "400"}
          >
            {node.text}
          </WcTypography>
        </WcStack>
      </WcStack>
    </WcStack>
  );
};
