"use client";

import React from "react";
import { MdArrowRight } from "react-icons/md";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { WcStack, WcTypography } from "@bpipartners/webui";
import { useTheme } from "@guberno/utils/theme";
import { useTranslations } from "next-intl";
import { RenderDrawerIcons } from "./RenderDrawerIcons";

interface CustomDrawerNodeProps {
  node: NodeModel;
  depth: number;
  hasChild: boolean;
  onToggle: (id: string | number) => void;
  isOpen: boolean;
  onSelect: (node: NodeModel) => void;
  isSelected: boolean;
}

export const CustomDrawerNode = ({
  onToggle,
  depth,
  isOpen,
  node,
  onSelect,
  isSelected,
  hasChild,
}: CustomDrawerNodeProps) => {
  const { id, data } = node;

  const t = useTranslations("common");
  const theme = useTheme();
  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => onSelect(node);

  // const dragOverProps = useDragOver(id, isOpen, onToggle);

  return (
    <WcStack
      id={`CustomDrawerNode${id}`}
      direction={"row"}
      sx={{
        width: "100%",
        // marginBottom: depth === 0 ? "8px" : 0,
        backgroundColor: isSelected ? theme.appPalette.primary[95] : "inherit",
        color: theme.appColors.primary,
        borderRadius: 8,
        padding: 4,
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
          background: theme.appPalette.primary[95],
          color: theme.appColors.primary,
        },
      }}
      height={theme.dimensions.appDrawerItemHeight}
      // {...dragOverProps}
    >
      <WcStack direction={"row"} width={"100%"} alignItems={"center"}>
        <WcStack
          width={"100%"}
          spacing={2}
          direction={"row"}
          alignItems={"center"}
          onClick={(event) => {
            //@ts-ignore
            if (data.type !== "group") {
              handleSelect();
            } else {
              handleToggle(event);
            }
          }}
        >
          {/* <WcIcon
            sx={{
              color: isSelected ? theme.appColors.onPrimary : "inherit",
              fontSize: 22,
              // paddingRight: 10,
            }}
          > */}
          <WcStack width={"20px"}>
            {
              //@ts-ignore
              data.icon !== "" && <RenderDrawerIcons itemType={node.text} />
            }
          </WcStack>
          {/* </WcIcon> */}
          <WcTypography variant="body1">{t(node.text)}</WcTypography>
        </WcStack>
      </WcStack>

      <WcStack
        sx={{
          fontSize: 0,
          cursor: "pointer",
          display: "flex",
          height: "24px",
          width: "24px",
          transition: "transform linear 0.1s",
          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
        }}
      >
        {hasChild && (
          <WcStack onClick={handleToggle}>
            <MdArrowRight />
          </WcStack>
        )}
      </WcStack>
    </WcStack>
  );
};
