import React from "react";
import { MdArrowRight } from "react-icons/md";
import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import {
  WcCheckBox,
  WcDivider,
  WcIcon,
  WcStack,
  WcTypography,
} from "@bpipartners/webui";
import { useTheme } from "@guberno/utils/theme";
import { MdCheck } from "react-icons/md";
import { TypeIcon } from "@guberno/ui";
import { useTranslations } from "next-intl";

interface CustomNodeProps {
  node: NodeModel;
  depth: number;
  hasChild: boolean;
  onToggle: (id: string | number) => void;
  isOpen: boolean;
  isAssessmentPlanning?: boolean;
  onSelect: (node: NodeModel) => void;
  isSelected: boolean;
}

export const CustomNode = ({
  onToggle,
  depth,
  isOpen,
  node,
  onSelect,
  isSelected,
  isAssessmentPlanning,
  hasChild,
}: CustomNodeProps) => {
  const { id, droppable, data } = node;
  const indent = depth * 12;
  const theme = useTheme();
  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => onSelect(node);
  const t = useTranslations();
  const dragOverProps = useDragOver(id, isOpen, onToggle);

  return (
    <WcStack
      id={"CustomNode" + id}
      sx={{
        width: "100%",
        cursor: "pointer",
        "&:hover": {
          background: theme.appPalette.neutral[99],
          color: theme.appColors.onPrimaryFixed,
        },
        color: isSelected ? theme.appColors.primary : "inherit",
        background: isSelected ? theme.appPalette.primary[95] : "inherit",
      }}
      {...dragOverProps}
    >
      <WcStack
        direction={"row"}
        sx={{
          paddingLeft: 24 + indent + "px",
          paddingRight: "24px",
          paddingTop: "16px",
          paddingBottom: "16px",
          alignItems: "center",
        }}
      >
        <WcStack direction={"row"} width={"100%"} alignItems={"center"}>
          {isAssessmentPlanning && (
            <WcStack
              width={"100%"}
              direction={"row"}
              paddingX={1}
              alignItems={"center"}
            >
              <WcCheckBox checked={isSelected} onChange={handleSelect} />
              <WcStack width={"100%"} onClick={handleToggle}>
                <WcTypography
                  // @ts-ignore
                  fontWeight={node.data.itemType === "SUBJECT" ? "bold" : "400"}
                  variant="body2"
                >
                  {node.text}
                </WcTypography>
              </WcStack>
            </WcStack>
          )}
          {!isAssessmentPlanning && (
            <WcStack
              justifyContent={"space-between"}
              width={"100%"}
              alignItems={"center"}
              paddingX={1}
              onClick={handleSelect}
              direction={"row"}
            >
              <WcStack width={"100%"}>
                <WcTypography
                  // @ts-ignore
                  variant="caption"
                >
                  {
                    // @ts-ignore
                    t(`labels.type${node.data.itemType}`)
                  }
                </WcTypography>

                <WcTypography
                  // @ts-ignore
                  variant={node.data.itemType === "SUBJECT" ? "body1" : "body2"}
                  // @ts-ignore
                  fontWeight={node.data.itemType === "SUBJECT" ? "bold" : "400"}
                >
                  {node.text}
                </WcTypography>
              </WcStack>
              {
                // @ts-ignore
                node.data.status && (
                  <WcIcon>
                    <MdCheck color={theme.appColors.tertiary} />
                  </WcIcon>
                )
              }
            </WcStack>
          )}
        </WcStack>
        <WcStack
          sx={{
            fontSize: 0,
            cursor: "pointer",
            display: "flex",
            height: "24px",
            width: "24px",
            transition: "transform linear 0.1s",
            transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
          }}
        >
          {hasChild && (
            <WcStack onClick={handleToggle}>
              <MdArrowRight fontSize="large" color={theme.appColors.primary} />
            </WcStack>
          )}
        </WcStack>
      </WcStack>
      <WcDivider orientation="horizontal" flexItem />
    </WcStack>
  );
};
